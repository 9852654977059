import { useEffect } from 'react';

import {
  fetchCultureSelectorData,
  useCultureSelectorData,
} from '@skyscanner-internal/global-components/culture-selector/helpers';
import HeaderGC from '@skyscanner-internal/global-components/header';
import { loginModalInit } from '@skyscanner-internal/global-components/header/helpers';
import logger from 'saddlebag-logger';
import { isLoggedIn } from 'saddlebag-user-management';

import { isBrowser, isProdEnv } from '../../utils/isEnv';
import withErrorBoundary from '../ErrorBoundary/WithErrorBoundary';

import type { HeaderProps } from '@skyscanner-internal/falcon-common-types/types/Header';
import type { HeaderProps as HeaderGCProps } from '@skyscanner-internal/global-components/header';

const Header = (props: HeaderProps) => {
  useEffect(() => {
    loginModalInit({ isProductionEnv: isProdEnv() });
  }, []);

  const {
    currencies,
    enMarkets,
    isFetchingCultureSelectorData,
    locales,
    markets,
    onCultureSelectorButtonClick,
  } = useCultureSelectorData(
    fetchCultureSelectorData,
    props.locale ?? 'en-GB',
    false,
  );
  const { cultureSelectorEnStrings, cultureSelectorStrings, ...filteredProps } =
    props;

  const userLoggedIn = isBrowser() ? isLoggedIn() : undefined;
  const headerGCProps: HeaderGCProps = {
    logger,
    userLoggedIn,
    ...filteredProps,
    cultureSelectorProps: {
      markets,
      locales,
      currencies,
      enMarkets,
      isProductionEnv: props.isProductionEnv,
      strings: cultureSelectorStrings,
      enStrings: cultureSelectorEnStrings,
    },
    isFetchingCultureSelectorData,
    onCultureSelectorButtonClick,
  };
  return (
    <div id="header-root">
      <HeaderGC {...headerGCProps} />
    </div>
  );
};

const HeaderWithErrorBoundary = withErrorBoundary(Header, 'header');

export default HeaderWithErrorBoundary;
